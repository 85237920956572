import { LabelInput } from "@/data/__generated__/types/generated";
import api from "@/data/api";

export const useHomePageApi = () => {
	const {
		getHomeLinks: { data: homeLinksData, loading: homeLinksLoading },
		getNewCollections: { data: newCollectionsData, loading: newCollectionsLoading },
		getCollectionByLabel: { data: popularCollectionsData, loading: popularCollectionLoading },
	} = {
		getHomeLinks: api.links.HomeLinks(),
		getCollectionByLabel: api.collection.CollectionsByLabel({
			input: {
				limit: 8,
				label: LabelInput.Popular,
			},
		}),
		getNewCollections: api.collection.NewCollections({ input: { limit: 8 } }),
	};

	return {
		homeLinksData,
		homeLinksLoading,
		newCollectionsData,
		newCollectionsLoading,
		popularCollectionLoading,
		popularCollectionsData,
	};
};
