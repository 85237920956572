"use client";

import type {
	ActiveCategoryResponse,
	PropertyGroupResponse,
	SliderCollectionResponse,
} from "@/data/__generated__/types/generated";
import type { TabsType } from "@/types";

import { useState } from "react";

import View from "./View";
import { useHomePageApi } from "./hooks/useHomePageApi";

type HomeProps = {
	activeCategory?: ActiveCategoryResponse;
	listType?: TabsType;
	sliderCollections: SliderCollectionResponse[];
	ssrCategoryFilters?: PropertyGroupResponse[];
};

const Home: React.FC<HomeProps> = ({ sliderCollections, activeCategory, listType, ssrCategoryFilters }) => {
	const [slideIndex, setSlideIndex] = useState(0);

	const {
		homeLinksData,
		homeLinksLoading,
		newCollectionsData,
		newCollectionsLoading,
		popularCollectionLoading,
		popularCollectionsData,
	} = useHomePageApi();

	return (
		<View
			sliderCollections={sliderCollections}
			slideIndex={slideIndex}
			setSlideIndex={setSlideIndex}
			homeLinks={homeLinksData?.links}
			homeLinksLoading={homeLinksLoading}
			popularCollections={popularCollectionsData?.collectionsByLabel}
			popularLoading={popularCollectionLoading}
			newCollections={newCollectionsData?.newCollections}
			newCollectionsLoading={newCollectionsLoading}
			activeCategory={activeCategory as ActiveCategoryResponse}
			listType={listType}
			ssrCategoryFilters={ssrCategoryFilters}
		/>
	);
};

export default Home;
