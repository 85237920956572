import type { SliderCollectionResponse } from "@/data/__generated__/types/generated";

import Image from "next/image";

import { getImageSource } from "@/helpers";

type SliderItemProps = {
	collection: SliderCollectionResponse;
};

const SliderItem: React.FC<SliderItemProps> = ({ collection }) => {
	return (
		<div>
			<Image
				className="object-cover sm:max-h-[200px]"
				priority
				width={894}
				height={448}
				alt={collection?.title}
				src={getImageSource({
					height: 448,
					width: 894,
					thumbnail: collection?.image || "",
					type: "collections",
					brand: collection?.slug,
				})}
			/>
		</div>
	);
};

export default SliderItem;
