import type { IconNameType } from "@RDN794312/xplitka-icons";

import { useState } from "react";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";
import Image from "next/image";

import { ThemeColor } from "@/types";

const SliderSectionAside: React.FC = () => {
	const [icon, setIcon] = useState({
		name: "arrowRight",
		color: ThemeColor.primaryBlack,
	});
	return (
		<aside className="min-w-[282px] flex flex-col">
			<button
				className="bg-accentGreen w-full h-[80px] store-map-button hover:bg-greenHover transition flex pt-[23px] px-6 font-medium text-lg text-white"
				type="button"
				aria-label="Посмотреть карту"
			>
				<a
					href="https://yandex.ru/maps/213/moscow/?from=mapframe&ll=37.611252%2C55.897848&mode=usermaps&source=mapframe&um=constructor%3AXhDBtO8xOoAlAzR4skLgGOfrzZonO9Wk&utm_source=mapframe&z=14"
					target="_blank"
					rel="noreferrer"
					className="hover:text-white"
				>
					<span>Магазины на карте</span>
				</a>
				<XPlitkaIcon name="arrowTopRight" color={ThemeColor.white} size={24} />
			</button>
			<Image width={282} height={290} src="/static/images/yandexmap.png" alt="map" />
			<a
				href="https://wa.me/79260129191"
				target="_blank"
				rel="noopener noreferrer"
				onMouseEnter={() => setIcon({ name: "arrowTopRight", color: ThemeColor.accentGreen })}
				onMouseLeave={() => setIcon({ name: "arrowRight", color: ThemeColor.primaryBlack })}
				className="h-[80px] relative flex px-6 pt-[30px] text-lg font-medium hover:text-accentGreen transition"
			>
				<span>Задать вопрос</span>
				<XPlitkaIcon
					name={icon.name as IconNameType}
					color={icon.color}
					size={24}
					className="relative top-[1.5px] ml-2"
				/>
				<Image
					width={282}
					height={80}
					className="absolute top-0 left-0 -z-10"
					src="/static/images/grayBorderButton.svg"
					alt="button_background"
				/>
			</a>
		</aside>
	);
};

export default SliderSectionAside;
